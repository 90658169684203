import apiClient from "../axiosConfig";


export const getCities = async () => {
    try {
        const response = await apiClient.get('/ObtenerCiudades');
        return response.data.map(city => ({
            value: city.id_ciudad,
            label: city.nombre_ciudad,
        }));

    } catch (error) {
        console.error('Error fetching categories:', error);
        return [];
    }
};
