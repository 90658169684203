import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCities } from '../utils/requestCandidates/getCities';

const CitiesContext = createContext();

export const CitiesProvider = ({ children }) => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const citiesData = await getCities(); 
        setCities(citiesData);
        setLoading(false);
      } catch (err) {
        setError('Error fetching cities');
        setLoading(false);
      }
    };

    fetchCities();
  }, []);

  return (
    <CitiesContext.Provider value={{ cities, loading, error }}>
      {children}
    </CitiesContext.Provider>
  );
};

export const useCities = () => useContext(CitiesContext);
