import apiClient from "../axiosConfig";


export const getCategories = async () => {
    try {
        const response = await apiClient.get('/ObtenerCategorias');
        return response.data.map(category => ({
            value: category.id_categoria,
            label: category.nombre_categoria,
        }));

    } catch (error) {
        console.error('Error fetching categories:', error);
        return [];
    }
};
