import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9637028d551b0d9c7ec6833660cda44c@o4507810754461696.ingest.us.sentry.io/4508219323252736",
  integrations: [],
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
});

const originalConsoleError = console.error;

console.error = (...args) => {
  const error = args[0] instanceof Error ? args[0] : new Error(args[0]);

  Sentry.withScope((scope) => {
    scope.setLevel('error');  
    scope.setTag("custom_tag", "Error en try-catch");  
    Sentry.captureException(error);
  });
  originalConsoleError(...args);
};
