import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCategories } from '../utils/requestCandidates/getCategories';

const CategoriesContext = createContext();

export const CategoriesProvider = ({ children }) => {
    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoading] = useState(true);
    const [errorCategories, setError] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const fetchedCategories = await getCategories();
                setCategories(fetchedCategories);
                setLoading(false);
            } catch (err) {
                setError('Error fetching categories');
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    return (
        <CategoriesContext.Provider value={{ categories, loadingCategories, errorCategories }}>
            {children}
        </CategoriesContext.Provider>
    );
};

export const useCategories = () => useContext(CategoriesContext);