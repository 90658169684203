import React from 'react';
import Background from '../components/Background';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="h-screen flex justify-center items-center">
            <Background />
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-stone-100">404</h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold  md:text-4xl text-stone-200 ">"¡Vaya! Esto no estaba en el itinerario de reclutamiento.".</p>
                    <p className="mb-4 text-lg font-normal text-stone-300 ">Parece que hemos encontrado un desvío en tu búsqueda de talento. ¿Por qué no aprovechas este momento para revisar tus estrategias de reclutamiento o explorar nuevas vías para encontrar al candidato perfecto? Estamos aquí para apoyarte en cada paso del proceso de contratación.</p>
                    <div className="flex items-center justify-center">
                        <Link to="/"  className='flex items-center p-4 text-slate-300 backdrop-blur-sm bg-sky-800/40 rounded-xl shadow-lg transition duration-300 transform hover:scale-105 hover:bg-sky-800 '
                            title='Finalizar entrevista'>
                            Volver al inicio
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
