import axios from "axios";

const environment = import.meta.env.VITE_FRONTEND_RECLUTACION_ENVIRONMENT;
console.log(environment); // Debería imprimir "dev"

// Configurar la URL base según el entorno
const baseURL =
  environment === "dev"
    ? "http://localhost:5000"
    : "https://back.mitrabajo.co";

// Crear una instancia de Axios con la URL base configurada
const apiClient = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClient;
