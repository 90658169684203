import React from 'react';

const Background = () => {
  return (
    // <div className=" absolute inset-0 -z-10 h-full w-full backdrop-blur-md [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#427aeb_100%)]"></div>
    // <div className=" absolute inset-0 -z-10 h-full w-full backdrop-blur-md [background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)]"></div>
  // <div className="absolute inset-0 -z-10 h-full w-full backdrop-blur-md bg-gradient-to-br from-color-secundario-900 from-30% via-color-secundario-800 via-65% to-color-principal-500 to-100%"></div>
  <div className="absolute inset-0 -z-10 h-full w-full bg-color-secundario-20 "></div>

  );
}

export default Background;