import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { UserProvider } from './context/UserContext';
import { CandidateProvider } from './context/CandidateContext';
import NotFound from './Pages/NotFound';
import Spinner from './components/general/Spinner';
import { CitiesProvider } from './context/CitiesContext';
import { CategoriesProvider } from './context/CategoriesContext';


const SignIn = lazy(() => import('./Pages/Authentication/SignIn'));
const SignUp = lazy(() => import('./Pages/Authentication/SignUp'));
const Dashboard = lazy(() => import('./Pages/Dashboard'));
const Landing = lazy(() => import('./Pages/Landing/Landing'));
const PortalJob = lazy(() => import('./Pages/Portal/PortalJob'));
const PortalLogin = lazy(() => import('./Pages/Portal/PortalLogin'));
const VacancyDetails = lazy(() => import('./Pages/Portal/VacancyDetails'));

const App = () => {
  return (
    <CitiesProvider>
      <UserProvider>
        <CategoriesProvider>
          <CandidateProvider>
            <Router>
              <Suspense fallback={
                <div className="flex items-center justify-center min-h-screen">
                  <Spinner />
                </div>
              }>
                <AppRoutes />
              </Suspense>
            </Router>
          </CandidateProvider>
        </CategoriesProvider>
      </UserProvider>
    </CitiesProvider>
  );
};

const AppRoutes = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['csrf_access_token']);
  const [authenticated, setAuthenticated] = useState(!!cookies.csrf_access_token);
  const [portalAuthenticated, setPortalAuthenticated] = useState(!!localStorage.getItem('candidate'));
  const [utmSource, setUtmSource] = useState(null);
  const [utmCampaign, setUtmCampaign] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const utm = queryParams.get('utm_source');
    if (utm) {
      setUtmSource(utm);
      localStorage.setItem('utm_source', utm);
    } else {
      const storedUtm = localStorage.getItem('utm_source');
      if (storedUtm) {
        setUtmSource(storedUtm);
      }
    }

    const companaUtmValue = queryParams.get('utm_campaign');
    if (companaUtmValue) {
      setUtmCampaign(companaUtmValue)
      localStorage.setItem('utm_campaign', companaUtmValue);
    } else {
      const storedCampanaUtm = localStorage.getItem('utm_campaign');
      if (storedCampanaUtm) {
        setUtmCampaign(storedCampanaUtm)
      }
    }
  }, [location.search]);

  useEffect(() => {
    const authCookie = cookies.csrf_access_token;
    if (authCookie) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [cookies.csrf_access_token]);

  useEffect(() => {
    const savedCandidate = localStorage.getItem('candidate');
    if (savedCandidate) {
      setPortalAuthenticated(true);
    } else {
      setPortalAuthenticated(false);
    }
  }, [[localStorage.getItem('candidate')]]);

  const handleLogout = () => {
    const environment = import.meta.env.VITE_FRONTEND_RECLUTACION_ENVIRONMENT;
    let domain;
    if (environment === 'dev') {
      domain = 'localhost';
    } else {
      domain = '.mitrabajo.co';
    }
    removeCookie('csrf_access_token', { path: '/', domain: domain, secure: true });
    localStorage.removeItem('user');
    setAuthenticated(false);
  };

  const authenticatePortal = () => {
    setPortalAuthenticated(true);
  };

  return (
    <Routes>
      <Route path="/" element={portalAuthenticated ? <PortalJob /> : <PortalLogin onAuthenticate={authenticatePortal} />} />
      <Route path="/vacancy/:id" element={<ProtectedVacancyDetails utmSource={utmSource} />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/dashboard/*" element={authenticated ? <Dashboard onLogout={handleLogout} /> : <Navigate to="/signin" />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/landing" element={<Landing />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const ProtectedVacancyDetails = ({ utmSource }) => {
  const portalAuthenticated = !!localStorage.getItem('candidate');
  const { id } = useParams();

  useEffect(() => {
    if (!portalAuthenticated) {
      localStorage.setItem('selectedJobId', id);
    }
    localStorage.setItem('vacanteRegister', id);
  }, [id, portalAuthenticated]);

  return portalAuthenticated ? (
    <VacancyDetails />
  ) : (
    <Navigate to="/" />
  );
};

export default App;
//Monorepo