import React, { createContext, useState, useEffect } from 'react';

export const CandidateContext = createContext();

export const CandidateProvider = ({ children }) => {
  const [candidate, setCandidate] = useState(() => {
    const savedCandidate = localStorage.getItem('candidate');
    return savedCandidate ? JSON.parse(savedCandidate) : null;
  });

  useEffect(() => {
    if (candidate) {
      localStorage.setItem('candidate', JSON.stringify(candidate));
    } else {
      localStorage.removeItem('candidate');
    }
  }, [candidate]);

  return (
    <CandidateContext.Provider value={{ candidate, setCandidate }}>
      {children}
    </CandidateContext.Provider>
  );
};
