// components/Spinner.js
import React from 'react';

const Spinner = () => {
  return (
    <div className="w-16 h-16 border-4 border-color-principal-500 border-t-transparent border-solid rounded-full animate-spin"></div>
  );
};

export default Spinner;
